/* You can add global styles to this file, and also import other style files */
.note-modal-backdrop {
  display: none !important;
}

@media print {
  .notPrintMe {
    visibility: hidden;
  }
}

.cell-custom {
  padding-right: 20px;
  text-align: right !important;
}

.header-custom {
  text-align: right;
}
