// Overrides user components-variables
@import "~bootstrap/scss/functions";

@import "../variables/variables";
@import "~bootstrap/scss/variables";
$white-color: (
  "base": #ffffff
);
$black-color: (
  "base": #000000
);
$primary-color: (
  "lighten-4": #EFDEFF,
  "lighten-3": #DCBDFF,
  "lighten-2": #C89CFF,
  "lighten-1": #B583FF,
  "base": #975AFF,
  "darken-1": #7441DB,
  "darken-2": #562DB7,
  "darken-3": #3B1C93,
  "darken-4": #28117A
);
$secondary-color: (
  "lighten-4": #EAF0F9,
  "lighten-3": #D6E1F3,
  "lighten-2": #B5C3DB,
  "lighten-1": #8F9DB8,
  "base": #616D89,
  "darken-1": #465375,
  "darken-2": #303C62,
  "darken-3": #1E294F,
  "darken-4": #121A41
);
$success-color: (
  "lighten-4": #E0FBDA,
  "lighten-3": #BDF8B6,
  "lighten-2": #8EEC8D,
  "lighten-1": #6CD975,
  "base": #40C057,
  "darken-1": #2EA54F,
  "darken-2": #208A47,
  "darken-3": #146F3E,
  "darken-4": #0C5C38
);
$danger-color: (
  "lighten-4": #FEE8DC,
  "lighten-3": #FECBBA,
  "lighten-2": #FCA897,
  "lighten-1": #F9877C,
  "base": #F55252,
  "darken-1": #D23B48,
  "darken-2": #B02940,
  "darken-3": #8E1A38,
  "darken-4": #750F33
);
$warning-color: (
  "lighten-4": #FEEFD0,
  "lighten-3": #FEDBA1,
  "lighten-2": #FCC173,
  "lighten-1": #FAA750,
  "base": #F77E17,
  "darken-1": #D46010,
  "darken-2": #B1460B,
  "darken-3": #8F2F07,
  "darken-4": #762004
);
$info-color: (
  "lighten-4": #D5F1FD,
  "lighten-3": #ACE0FC,
  "lighten-2": #81C8F7,
  "lighten-1": #60AFF0,
  "base": #2F8BE6,
  "darken-1": #226CC5,
  "darken-2": #1750A5,
  "darken-3": #0E3885,
  "darken-4": #09276E
);
$light: (
  "base": #DBDEE5
);
$dark: (
  "base": #1A051D
);

$grey-color: (
  "lighten-4": #f5f5f5,
  "lighten-3": #eee,
  "lighten-2": #e0e0e0,
  "lighten-1": #bdbdbd,
  "base": #9e9e9e,
  "darken-1": #757575,
  "darken-2": #616161,
  "darken-3": #424242,
  "darken-4": #212121
);
$blue-grey: (
  "lighten-5":  #eceff1,
  "lighten-4":  #cfd8dc,
  "lighten-3":  #b0bec5,
  "lighten-2":  #90a4ae,
  "lighten-1":  #78909c,
  "base":       #607d8b,
  "darken-1":   #546e7a,
  "darken-2":   #455a64,
  "darken-3":   #37474f,
  "darken-4":   #263238,
  "accent-1":   #cfd8dc,
  "accent-2":   #b0bec5,
  "accent-3":   #78909c,
  "accent-4":   #455a64
);
$red-color: (
  "lighten-5":  #ffebee,
  "lighten-4":  #ffcdd2,
  "lighten-3":  #ef9a9a,
  "lighten-2":  #e57373,
  "lighten-1":  #ef5350,
  "base":       #f44336,
  "darken-1":   #e53935,
  "darken-2":   #d32f2f,
  "darken-3":   #c62828,
  "darken-4":   #b71c1c,
  "accent-1":   #ff8a80,
  "accent-2":   #ff5252,
  "accent-3":   #ff1744,
  "accent-4":   #d50000
);
$pink: (
  "lighten-5":  #fce4ec,
  "lighten-4":  #f8bbd0,
  "lighten-3":  #f48fb1,
  "lighten-2":  #f06292,
  "lighten-1":  #ec407a,
  "base":       #e91e63,
  "darken-1":   #d81b60,
  "darken-2":   #c2185b,
  "darken-3":   #ad1457,
  "darken-4":   #880e4f,
  "accent-1":   #ff80ab,
  "accent-2":   #ff4081,
  "accent-3":   #f50057,
  "accent-4":   #c51162
);
$purple: (
  "lighten-5":  #f3e5f5,
  "lighten-4":  #e1bee7,
  "lighten-3":  #ce93d8,
  "lighten-2":  #ba68c8,
  "lighten-1":  #ab47bc,
  "base":       #9c27b0,
  "darken-1":   #8e24aa,
  "darken-2":   #7b1fa2,
  "darken-3":   #6a1b9a,
  "darken-4":   #4a148c,
  "accent-1":   #ea80fc,
  "accent-2":   #e040fb,
  "accent-3":   #d500f9,
  "accent-4":   #a0f
);
$deep-purple: (
  "lighten-5":  #ede7f6,
  "lighten-4":  #d1c4e9,
  "lighten-3":  #b39ddb,
  "lighten-2":  #9575cd,
  "lighten-1":  #7e57c2,
  "base":       #673ab7,
  "darken-1":   #5e35b1,
  "darken-2":   #512da8,
  "darken-3":   #4527a0,
  "darken-4":   #311b92,
  "accent-1":   #b388ff,
  "accent-2":   #7c4dff,
  "accent-3":   #651fff,
  "accent-4":   #6200ea
);
$indigo: (
  "lighten-5":  #e8eaf6,
  "lighten-4":  #c5cae9,
  "lighten-3":  #9fa8da,
  "lighten-2":  #7986cb,
  "lighten-1":  #5c6bc0,
  "base":       #3f51b5,
  "darken-1":   #3949ab,
  "darken-2":   #303f9f,
  "darken-3":   #283593,
  "darken-4":   #1a237e,
  "accent-1":   #8c9eff,
  "accent-2":   #536dfe,
  "accent-3":   #3d5afe,
  "accent-4":   #304ffe
);
$blue: (
  "lighten-5":  #e3f2fd,
  "lighten-4":  #bbdefb,
  "lighten-3":  #90caf9,
  "lighten-2":  #64b5f6,
  "lighten-1":  #42a5f5,
  "base":       #2196f3,
  "darken-1":   #1e88e5,
  "darken-2":   #1976d2,
  "darken-3":   #1565c0,
  "darken-4":   #0d47a1,
  "accent-1":   #82b1ff,
  "accent-2":   #448aff,
  "accent-3":   #2979ff,
  "accent-4":   #2962ff
);
$light-blue: (
  "lighten-5":  #e1f5fe,
  "lighten-4":  #b3e5fc,
  "lighten-3":  #81d4fa,
  "lighten-2":  #4fc3f7,
  "lighten-1":  #29b6f6,
  "base":       #03a9f4,
  "darken-1":   #039be5,
  "darken-2":   #0288d1,
  "darken-3":   #0277bd,
  "darken-4":   #01579b,
  "accent-1":   #80d8ff,
  "accent-2":   #40c4ff,
  "accent-3":   #00b0ff,
  "accent-4":   #0091ea
);
$cyan: (
  "lighten-5":  #e0f7fa,
  "lighten-4":  #b2ebf2,
  "lighten-3":  #80deea,
  "lighten-2":  #4dd0e1,
  "lighten-1":  #26c6da,
  "base":       #00bcd4,
  "darken-1":   #00acc1,
  "darken-2":   #0097a7,
  "darken-3":   #00838f,
  "darken-4":   #006064,
  "accent-1":   #84ffff,
  "accent-2":   #18ffff,
  "accent-3":   #00e5ff,
  "accent-4":   #00b8d4
);
$teal: (
  "lighten-5":  #e0f2f1,
  "lighten-4":  #b2dfdb,
  "lighten-3":  #80cbc4,
  "lighten-2":  #4db6ac,
  "lighten-1":  #26a69a,
  "base":       #009688,
  "darken-1":   #00897b,
  "darken-2":   #00796b,
  "darken-3":   #00695c,
  "darken-4":   #004d40,
  "accent-1":   #a7ffeb,
  "accent-2":   #64ffda,
  "accent-3":   #1de9b6,
  "accent-4":   #00bfa5
);
$green: (
  "lighten-5":  #e8f5e9,
  "lighten-4":  #c8e6c9,
  "lighten-3":  #a5d6a7,
  "lighten-2":  #81c784,
  "lighten-1":  #66bb6a,
  "base":       #4caf50,
  "darken-1":   #43a047,
  "darken-2":   #388e3c,
  "darken-3":   #2e7d32,
  "darken-4":   #1b5e20,
  "accent-1":   #b9f6ca,
  "accent-2":   #69f0ae,
  "accent-3":   #00e676,
  "accent-4":   #00c853
);
$light-green: (
  "lighten-5":  #f1f8e9,
  "lighten-4":  #dcedc8,
  "lighten-3":  #c5e1a5,
  "lighten-2":  #aed581,
  "lighten-1":  #9ccc65,
  "base":       #8bc34a,
  "darken-1":   #7cb342,
  "darken-2":   #689f38,
  "darken-3":   #558b2f,
  "darken-4":   #33691e,
  "accent-1":   #ccff90,
  "accent-2":   #b2ff59,
  "accent-3":   #76ff03,
  "accent-4":   #64dd17
);
$lime: (
  "lighten-5":  #f9fbe7,
  "lighten-4":  #f0f4c3,
  "lighten-3":  #e6ee9c,
  "lighten-2":  #dce775,
  "lighten-1":  #d4e157,
  "base":       #cddc39,
  "darken-1":   #c0ca33,
  "darken-2":   #afb42b,
  "darken-3":   #9e9d24,
  "darken-4":   #827717,
  "accent-1":   #f4ff81,
  "accent-2":   #eeff41,
  "accent-3":   #c6ff00,
  "accent-4":   #aeea00
);
$yellow: (
  "lighten-5":  #fffde7,
  "lighten-4":  #fff9c4,
  "lighten-3":  #fff59d,
  "lighten-2":  #fff176,
  "lighten-1":  #ffee58,
  "base":       #ffeb3b,
  "darken-1":   #fdd835,
  "darken-2":   #fbc02d,
  "darken-3":   #f9a825,
  "darken-4":   #f57f17,
  "accent-1":   #ffff8d,
  "accent-2":   #ff0,
  "accent-3":   #ffea00,
  "accent-4":   #ffd600
);
$amber: (
  "lighten-5":  #fff8e1,
  "lighten-4":  #ffecb3,
  "lighten-3":  #ffe082,
  "lighten-2":  #ffd54f,
  "lighten-1":  #ffca28,
  "base":       #ffc107,
  "darken-1":   #ffb300,
  "darken-2":   #ffa000,
  "darken-3":   #ff8f00,
  "darken-4":   #ff6f00,
  "accent-1":   #ffe57f,
  "accent-2":   #ffd740,
  "accent-3":   #ffc400,
  "accent-4":   #ffab00
);
$orange: (
  "lighten-5":  #fff3e0,
  "lighten-4":  #ffe0b2,
  "lighten-3":  #ffcc80,
  "lighten-2":  #ffb74d,
  "lighten-1":  #ffa726,
  "base":       #ff9800,
  "darken-1":   #fb8c00,
  "darken-2":   #f57c00,
  "darken-3":   #ef6c00,
  "darken-4":   #e65100,
  "accent-1":   #ffd180,
  "accent-2":   #ffab40,
  "accent-3":   #ff9100,
  "accent-4":   #ff6d00
);
$deep-orange: (
  "lighten-5":  #fbe9e7,
  "lighten-4":  #ffccbc,
  "lighten-3":  #ffab91,
  "lighten-2":  #ff8a65,
  "lighten-1":  #ff7043,
  "base":       #ff5722,
  "darken-1":   #f4511e,
  "darken-2":   #e64a19,
  "darken-3":   #d84315,
  "darken-4":   #bf360c,
  "accent-1":   #ff9e80,
  "accent-2":   #ff6e40,
  "accent-3":   #ff3d00,
  "accent-4":   #dd2c00
);
$brown: (
  "lighten-5":  #efebe9,
  "lighten-4":  #d7ccc8,
  "lighten-3":  #bcaaa4,
  "lighten-2":  #a1887f,
  "lighten-1":  #8d6e63,
  "base":       #795548,
  "darken-1":   #6d4c41,
  "darken-2":   #5d4037,
  "darken-3":   #4e342e,
  "darken-4":   #3e2723,
  "accent-1":   #d7ccc8,
  "accent-2":   #bcaaa4,
  "accent-3":   #8d6e63,
  "accent-4":   #5d4037
);


$colors: (
  "white": $white-color,
  "black": $black-color,
  "primary": $primary-color,
  "secondary": $secondary-color,
  "success": $success-color,
  "danger": $danger-color,
  "warning": $warning-color,
  "info": $info-color,
  "light": $light,
  "dark": $dark,
  "grey": $grey-color,
  "blue-grey": $blue-grey,
  "red": $red-color,
  "pink": $pink,
  "purple": $purple,
  "deep-purple": $deep-purple,
  "indigo": $indigo,
  "blue": $blue,
  "light-blue": $light-blue,
  "cyan": $cyan,
  "teal": $teal,
  "green": $green,
  "light-green": $light-green,
  "lime": $lime,
  "yellow": $yellow,
  "amber": $amber,
  "orange": $orange,
  "deep-orange": $deep-orange,
  "brown": $brown
  );

$gradient-colors: (
  gradient-mint: (
    "start-color": #226CC5,
    "end-color": #6CD975
  ),
  gradient-pomegranate: (
    "start-color": #465375,
    "end-color": #F55252
  ),
  gradient-blackberry: (
    "start-color": #303C62,
    "end-color": #B5C3DB
  ),
  gradient-king-yna: (
    "start-color": #7441DB,
    "end-color": #F9877C
  ),
  gradient-ibiza-sunset: (
    "start-color": #B02940,
    "end-color": #FCC173
  ),
  gradient-flickr: (
    "start-color": #F77E17,
    "end-color": #2F8BE6
  ),
  gradient-purple-bliss: (
    "start-color": #562DB7,
    "end-color": #60AFF0
  ),
  gradient-man-of-steel: (
    "start-color": #2F3EA0,
    "end-color": #AE342D
  ),
  gradient-purple-love: (
    "start-color": #7441DB,
    "end-color": #C89CFF
  ),
  gradient-starfall: (
    "start-color": #D23B48,
    "end-color": #2F8BE6
  ),
  gradient-custom-dark: (
    'start-color' : #37474f,
      'end-color' : #364147,
)
);
